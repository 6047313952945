:root {
  --custom-pink: #ffa4f4;
  --custom-blue: #a3f1ff;
  --custom-yellow: #fefebf;
}


.nav-tabs {
  border-bottom-color: var(--custom-pink);
  
}

.bg-lightpink {
  background-color: var(--custom-pink);
}

.btn-lightpink {
  color:#fff;
  background-color: var(--custom-pink);
  border-color: var(--custom-pink);
}

.page-header {
  font-size: 3.5rem;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border-color: var(--custom-pink) var(--custom-pink) #fff;
  color: white;
  background-color: var(--custom-blue);
 }

 .category-item {
   cursor: pointer;
 }

 .category-item-selected, .category-item:hover {
   color: white;
   background-color: var(--custom-blue);
 }

 .input-error {
   width: 100%;
   margin-top: .25rem;
   font-size: 0.875rem;
   color:#dc3545;
 }

 .blogTop {
   padding-left: 10px;
 }

 .draft-editor {
   min-height: 40vh;
   padding-left: 10px;
   padding-right: 10px;
   font-family: 'Roboto', sans-serif !important;
   font-size: 20px;
 }


 .rdw-dropdown-wrapper a {
   color: inherit !important;
   text-decoration: none !important;
 }

 .blogTitle {
  font-family: 'Lato', sans-serif !important;
  font-weight: 600 !important;
 }

 .draft-editor img {
    max-width: 100%;
    height: auto; 
  }

  .draft-editor figure {
    text-align: center;
  }

 .spinning-logo {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
 }

 .cursor-pointer {
   cursor: pointer !important;
 }

 @keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}




